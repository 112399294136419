.progress-bar {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 5px;
	z-index: 20;
	background-color: rgba(127, 219, 255, .5);
	overflow: hidden;
}

.progress-bar::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transform-origin: 0 50%;
	background-color: rgb(127, 219, 255);
	animation-name: indeterminate-progress;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-timing-function: linear;
}

@keyframes indeterminate-progress {
	0% {
		transform: translateX(0) scaleX(0);
	}
	40% {
		transform: translateX(0) scaleX(0.5);
	}
	100% {
		transform: translateX(100%) scaleX(0.5);
	}
}
