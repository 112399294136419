.app {
	font-family: "PT Sans", sans-serif;
	min-width: 360px;
}


a {
	color: #4a90e2;
}


.app__content-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
}

