.product-card {
	flex: 1 0 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 12px;
	padding-right: 12px;
	text-align: center;
	max-width: 100%;
	box-sizing: border-box;
	margin-bottom: 24px;
}

.product-card__thumbnail {
	max-width: 350px;
	max-height: 260px;
}

.product-card__thumbnail-wrapper {
	width: 350px;
	height: 260px;
	display: flex;
	align-items: center;
	justify-content: center;
}
