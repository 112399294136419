.products-page {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -12px;
	margin-right: -12px;
}

.products-page--has-single-product {
	justify-content: center;
}
