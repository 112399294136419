.product-details__image {
	display: block;
	max-width: 100%;
	max-height: 365px;
	margin-left: auto;
	margin-right: auto;
}

.product-details__description figure img {
	max-width: 100%;
	max-height: 365px;
	margin-left: auto;
	margin-right: auto;
}
