.navigation-bar {
	background-color: #333333;
	display: flex;
	justify-content: space-between;
	height: 72px;
}

.navigation-bar__app-menu {
	display: flex;
}
.navigation-bar__app-menu-button {
	display: block;
	padding: 26px 40px;
	font-size: 25px;
	line-height: 20px;
	color: #FFFFFF;
	font-weight: bold;
	text-decoration: none;
}

.navigation-bar__app-menu-button:hover {
	background-color: #444444;
}

.navigation-bar__app-menu-button--active {
	background-color: #F6F6F6;
	color: #333333;
}

.navigation-bar__app-menu-button--active:hover {
	background-color: #F6F6F6;
	color: #333333;
}
