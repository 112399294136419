.app-title {
	width: 100%;
	height: 190px;
	background-color: #F6F6F6;
	text-align: center;
	font-size: 61px;
	line-height: 190px;
	font-weight: bold;
}

.app-content {
	box-sizing: border-box;
	width: 100%;
	max-width: 948px;
	text-align: justify;
	font-size: 22px;
	padding: 24px;
}
